import request from '@/utils/request'

// 确认接单
export function confirmSubscribe(id, data) {
  return request({
    url: `/subscribe_orders/${id}/confirm`,
    method: 'post',
    data
  })
}

// 确认发货
export function subscribeOrderDeliver(id, data) {
  return request({
    url: `/subscribe_orders/${id}/deliver`,
    method: 'post',
    data
  })
}

// 订单取消
export function subscribeOrderCancel(id, data) {
  return request({
    url: `/subscribe_orders/${id}/cancel`,
    method: 'post',
    data
  })
}
