<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="确认接单"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <span>确定接单后，若有相关花房派工、配货等任务，请督促完成，尽快发货。</span>
        <a-form-item label="货品配货负责人" v-show="productTasks && productTasks.indexOf('assorting') !== -1">
          <a-select
            v-decorator="['assorting_user_id',{
              initialValue: defaultAssortUser.id > 0 ? defaultAssortUser.id : undefined,
              rules: [
                { required: true, message: '请选择货品配货负责人' },
              ]
            }]"
            placeholder="请选择货品配货负责人"
            @load="loadingAssortUserOptions"
            @change="handleAssortingPhoneNumberChange"
          >
            <a-select-option
              v-for="user in assortUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话" v-show="productTasks && productTasks.indexOf('assorting') !== -1">
          <a-input
            :disabled="true"
            v-decorator="['assorting_phone_number', {
              initialValue: defaultAssortUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="花房派工负责人" v-show="productTasks && productTasks.indexOf('dispatching') !== -1">
          <a-select
            v-decorator="['dispatching_user_id', {
              initialValue: defaultDispatchUser.id > 0 ? defaultDispatchUser.id : undefined,
              rules: [
                { required: true, message: '请选择花房派工负责人' },
              ]
            }]"
            placeholder="请选择花房派工负责人"
            @load="loadingDispatchUserOptions"
            @change="handleDispatchingPhoneNumberChange"
          >
            <a-select-option
              v-for="user in dispatchUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话" v-show="productTasks && productTasks.indexOf('dispatching') !== -1">
          <a-input
            :disabled="true"
            v-decorator="['dispatching_user_phone_number', {
              normalize: this.$lodash.trim,
              initialValue: defaultDispatchUser.phone_number,
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="厅房布置负责人" v-show="productTasks && productTasks.indexOf('hall_layout') !== -1">
          <a-select
            v-decorator="['hall_layout_user_id', {
              initialValue: defaultLayoutUser.id > 0 ? defaultLayoutUser.id : undefined,
              rules: [
                { required: true, message: '请选择厅房布置负责人' },
              ]
            }]"
            placeholder="请选择厅房布置负责人"
            @load="loadingLayoutUserOptions"
            @change="handleHallPhoneNumberChange"
          >
            <a-select-option
              v-for="user in layoutUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话" v-show="productTasks && productTasks.indexOf('hall_layout') !== -1">
          <a-input
            :disabled="true"
            v-decorator="['hall_phone_number', {
              normalize: this.$lodash.trim,
              initialValue: defaultLayoutUser.phone_number
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { message: '请输入备注' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarOptions } from '@/api/car'
import { confirmSubscribe } from '@/api/subscribe_order'
import { additionalItemOrderConfirm } from '@/api/additional_item_order'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'ConfirmOrder',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    productTasks: {
      type: []
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirm_order' }),
      submitting: false,
      defaultFileList: [],
      assortUserOptions: [],
      defaultAssortUser: {},
      dispatchUserOptions: [],
      defaultDispatchUser: {},
      layoutUserOptions: [],
      defaultLayoutUser: {},
      carOptions: [],
      loadingCarOptions: false,
      loadingAssortUserOptions: false,
      loadingDispatchUserOptions: false,
      loadingLayoutUserOptions: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
  },
  methods: {
    handleDispatchingPhoneNumberChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ dispatching_user_phone_number: res.data.phone_number })
        }
      })
    },
    handleAssortingPhoneNumberChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ assorting_phone_number: res.data.phone_number })
        }
      })
    },
    handleHallPhoneNumberChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ hall_phone_number: res.data.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingAssortUserOptions = true
      this.loadingDispatchUserOptions = true
      this.loadingLayoutUserOptions = true
      findTenantTaskUserOptions({ task_type: 'assorting', order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.assortUserOptions = res.data.option_list
          this.defaultAssortUser = res.data.default_user
        }
        this.loadingAssortUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'dispatching', order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.dispatchUserOptions = res.data.option_list
          this.defaultDispatchUser = res.data.default_user
        }
        this.loadingDispatchUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'hall_layout', order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.layoutUserOptions = res.data.option_list
          this.defaultLayoutUser = res.data.default_user
        }
        this.loadingLayoutUserOptions = false
      })
    },
    fetchCarOptions() {
      this.loadingCarOptions = true
      findCarOptions({ order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.carOptions = res.data.option_list
        }
        this.loadingCarOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (this.orderType === 'subscribe') {
            confirmSubscribe(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          } else {
            additionalItemOrderConfirm(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          }
        }
      })
    }
  }
}
</script>
