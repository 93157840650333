var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"确认接单"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('span',[_vm._v("确定接单后，若有相关花房派工、配货等任务，请督促完成，尽快发货。")]),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('assorting') !== -1),expression:"productTasks && productTasks.indexOf('assorting') !== -1"}],attrs:{"label":"货品配货负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['assorting_user_id',{
            initialValue: _vm.defaultAssortUser.id > 0 ? _vm.defaultAssortUser.id : undefined,
            rules: [
              { required: true, message: '请选择货品配货负责人' } ]
          }]),expression:"['assorting_user_id',{\n            initialValue: defaultAssortUser.id > 0 ? defaultAssortUser.id : undefined,\n            rules: [\n              { required: true, message: '请选择货品配货负责人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择货品配货负责人"},on:{"load":_vm.loadingAssortUserOptions,"change":_vm.handleAssortingPhoneNumberChange}},_vm._l((_vm.assortUserOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('assorting') !== -1),expression:"productTasks && productTasks.indexOf('assorting') !== -1"}],attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['assorting_phone_number', {
            initialValue: _vm.defaultAssortUser.phone_number,
            normalize: this.$lodash.trim
          }]),expression:"['assorting_phone_number', {\n            initialValue: defaultAssortUser.phone_number,\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('dispatching') !== -1),expression:"productTasks && productTasks.indexOf('dispatching') !== -1"}],attrs:{"label":"花房派工负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dispatching_user_id', {
            initialValue: _vm.defaultDispatchUser.id > 0 ? _vm.defaultDispatchUser.id : undefined,
            rules: [
              { required: true, message: '请选择花房派工负责人' } ]
          }]),expression:"['dispatching_user_id', {\n            initialValue: defaultDispatchUser.id > 0 ? defaultDispatchUser.id : undefined,\n            rules: [\n              { required: true, message: '请选择花房派工负责人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择花房派工负责人"},on:{"load":_vm.loadingDispatchUserOptions,"change":_vm.handleDispatchingPhoneNumberChange}},_vm._l((_vm.dispatchUserOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('dispatching') !== -1),expression:"productTasks && productTasks.indexOf('dispatching') !== -1"}],attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dispatching_user_phone_number', {
            normalize: this.$lodash.trim,
            initialValue: _vm.defaultDispatchUser.phone_number,
          }]),expression:"['dispatching_user_phone_number', {\n            normalize: this.$lodash.trim,\n            initialValue: defaultDispatchUser.phone_number,\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('hall_layout') !== -1),expression:"productTasks && productTasks.indexOf('hall_layout') !== -1"}],attrs:{"label":"厅房布置负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_layout_user_id', {
            initialValue: _vm.defaultLayoutUser.id > 0 ? _vm.defaultLayoutUser.id : undefined,
            rules: [
              { required: true, message: '请选择厅房布置负责人' } ]
          }]),expression:"['hall_layout_user_id', {\n            initialValue: defaultLayoutUser.id > 0 ? defaultLayoutUser.id : undefined,\n            rules: [\n              { required: true, message: '请选择厅房布置负责人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择厅房布置负责人"},on:{"load":_vm.loadingLayoutUserOptions,"change":_vm.handleHallPhoneNumberChange}},_vm._l((_vm.layoutUserOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.productTasks && _vm.productTasks.indexOf('hall_layout') !== -1),expression:"productTasks && productTasks.indexOf('hall_layout') !== -1"}],attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_phone_number', {
            normalize: this.$lodash.trim,
            initialValue: _vm.defaultLayoutUser.phone_number
          }]),expression:"['hall_phone_number', {\n            normalize: this.$lodash.trim,\n            initialValue: defaultLayoutUser.phone_number\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { message: '请输入备注' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { message: '请输入备注' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }