import request from '@/utils/request'

// 确认接单
export function additionalItemOrderConfirm(id, data) {
  return request({
    url: `/additional_item_orders/${id}/confirm`,
    method: 'post',
    data
  })
}

// 确认发货
export function additionalItemOrderDeliver(id, data) {
  return request({
    url: `/additional_item_orders/${id}/deliver`,
    method: 'post',
    data
  })
}

// 订单取消
export function additionalItemOrderCancel(id, data) {
  return request({
    url: `/additional_item_orders/${id}/cancel`,
    method: 'post',
    data
  })
}
